import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';

import { COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID } from 'data/constants/auth';
import paths from 'utils/paths';

const DEMO_ID_TOKEN =
  'eyJraWQiOiI2blcxK2ZSVlF2T2oydlhiZ28rbVlzaXBWRHhzY3pxR2M1Vm9GbzdDQmxVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3MGI0MzExMi05ZjIzLTQwM2ItOWFjNS1lZGQ5NGNjYzI2ZGYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicHJvZmlsZXMiOiJbe1wibmFtZXNwYWNlXCI6IFwiZGVtb1wiLCBcImNvbXBhbnlfaWRcIjogXCI1ZmNjMDgxNi01NTdlLTQxZGEtYTNlZS02NTk1ZWMwM2FlZjVcIiwgXCJwcm9maWxlX2lkXCI6IFwiZTg4YTMxNjMtMTkxYy00NmJhLWFmOTMtZDFkNmEyN2IwYjk5XCJ9XSIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAuZXUtd2VzdC0yLmFtYXpvbmF3cy5jb20vZXUtd2VzdC0yX2t6OUlIVnJzUyIsImNvZ25pdG86dXNlcm5hbWUiOiJ0ZXN0LTE2ODcyNTA5ODM2MjYiLCJnaXZlbl9uYW1lIjoiVGVzdCIsIm9yaWdpbl9qdGkiOiJiOGQ3MTE2ZS1jNzYxLTQzY2EtYjUxMy1kNmUzZTk5M2ZiNGMiLCJhdWQiOiI3MDM2ZWN1a2t0bjUzMzJ0YjJpaGs1Z290IiwiZXZlbnRfaWQiOiJlNTI1NzY4OC1mNWVlLTQ4ZmMtYTQ4Ny02OWI2MGRkYTQyMTgiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTczNjE5NDAxMywiZXhwIjoxNzM2MTk3NjEyLCJpYXQiOjE3MzYxOTQwMTMsImZhbWlseV9uYW1lIjoiVXNlciIsImp0aSI6ImQ4ZDdhMWRhLTM3ZjQtNGJjMi04ZGJmLTBjOGViYWYxMzBiNCIsImVtYWlsIjoidGVzdEBkZW1vLmNvbSJ9.d3w6mcMhANB3JuXrs8P46u8tM7usRuWLPorplLAVSSLBKirVUXyPdwMZpOvHNCogH5LblkKg0vavUNb9HdYe14VxeTWT_r-OFLl_3XuF7KtnelGMSZIryx6tXs_XzuZHAwgWKXtTC_podQpTf0EZTWgrWnJPeKFrSBwFsMrsSAuydD6wdB513ONWuvI67ZQXRfO0SxyrJHh6Iy4QLto7R4BBgZz1azUGyCjHPtcTOC5hifp0Gyyg6bA1U4-pGH0iUKhuS07P4E0xg_I2dv91Dw2eCFlbXPGsUBb977xWV_Y_uyfEgRMMMrAlxx6aYMQr3gBqjozBoiNRcKW3cGqWhw';

// HACK: amplify automatically handles /oauth route so we need to temporarily
// disable replaceState to unexpected redirect and broken /oauth route
if (typeof window != 'undefined') {
  // save current replaceState so we can revert to original later
  const replaceState = window.history.replaceState;

  // temporarily set replaceState with noop
  if (window.location.pathname.includes('/oauth')) {
    window.history.replaceState = () => false;
  }

  // configure auth listener
  Hub.listen('auth', (data) => {
    if (data.payload.event === 'signIn_failure') {
      // revert replaceState to original
      window.history.replaceState = (...args) => {
        replaceState.apply(window.history, args);
      };
    }
  });
}

// UNCOMMENT FOR COGNITO
// Amplify.configure({
//   Auth: {
//     region: AWS_REGION,
//     userPoolId: COGNITO_USER_POOL_ID,
//     userPoolWebClientId: COGNITO_CLIENT_ID,
//     mandatorySignIn: false,

//     oauth: {
//       domain: AUTH_DOMAIN,
//       redirectSignIn: APP_URL,
//       redirectSignOut: `${APP_URL}/login`,
//       clientId: COGNITO_CLIENT_ID,
//       responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
//     },
//   },
// });

export async function getCognitoUser(): Promise<CognitoUser | undefined> {
  return await Auth.currentAuthenticatedUser();
}

export async function signOut() {
  await Auth.signOut();
}

export function customAuth(
  userName: string | null,
  loginToken: string | null,
): void {
  if (!userName) return;
  if (!loginToken) return;

  signOut();

  const pool = new CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
  });

  const authData = new AuthenticationDetails({
    Username: userName,
  });
  const cognitoUser = new CognitoUser({
    Username: userName,
    Pool: pool,
  });
  cognitoUser.challengeName = 'CUSTOM_CHALLENGE';

  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

  cognitoUser.initiateAuth(authData, {
    onSuccess: async () => {
      await refreshAndGetNewIdToken();
      window.location.href = paths.missionControl.index;
    },
    onFailure: (error) => {
      alert(error);
    },
    customChallenge: async () => {
      await Auth.sendCustomChallengeAnswer(cognitoUser, loginToken);
    },
  });
}

export async function getIdToken(): Promise<{
  idToken: string | null;
  expiry: number | null;
}> {
  return Promise.resolve({
    idToken: DEMO_ID_TOKEN,
    expiry: 1000 * 60 * 60 * 24,
  });
  // UNCOMMENT FOR COGNITO
  // try {
  //   const user = await getCognitoUser();
  //   if (!user) return { idToken: null, expiry: null };
  //   const token = user.getSignInUserSession()?.getIdToken();
  //   const idToken = token?.getJwtToken() ?? null;
  //   const expiry = token?.getExpiration() ?? null;
  //   if (!token) throw new Error('Could not get user token');
  //   return { idToken, expiry };
  // } catch (error) {
  //   console.error(error);
  // }
  // return { idToken: null, expiry: null };
}

export const USER_SESSION_ID_LOCAL_STORAGE_KEY = 'user_session_id';

export const saveSessionIdToLocalStorage = (user: CognitoUser) => {
  const sessionID = user.getSignInUserSession()?.getIdToken().getJwtToken();
  localStorage.setItem(USER_SESSION_ID_LOCAL_STORAGE_KEY, sessionID || '');
};

export const getLocalStorageSessionId = () => {
  return localStorage.getItem(USER_SESSION_ID_LOCAL_STORAGE_KEY) ?? 'anonymous';
};

export async function refreshAndGetNewIdToken() {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = cognitoUser.getSignInUserSession();
  const refreshToken = currentSession?.getRefreshToken();
  if (!refreshToken) {
    throw new Error('Could not get refresh token');
  }
  return Promise.resolve({
    idToken: DEMO_ID_TOKEN,
    expiry: 1000 * 60 * 60 * 24,
  });
  // UNCOMMENT FOR COGNITO
  // return new Promise<{ idToken: string; expiry: number }>((resolve) => {
  //   cognitoUser.refreshSession(
  //     refreshToken,
  //     (_err, session: CognitoUserSession) => {
  //       const token = session?.getIdToken();
  //       const idToken = token?.getJwtToken() ?? null;
  //       localStorage.setItem(USER_SESSION_ID_LOCAL_STORAGE_KEY, idToken || '');
  //       const expiry = token?.getExpiration() ?? null;
  //       if (idToken) {
  //         resolve({ idToken, expiry });
  //       }
  //     },
  //   );
  // });
}

export let preSignUpFailed = false;

Hub.listen('auth', async ({ payload }) => {
  const { event, data } = payload;

  if (event === 'signIn_failure' && data.message.startsWith('PreSignUp')) {
    preSignUpFailed = true;
  }
});

export { Hub };
